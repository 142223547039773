import { Box, createTheme, Grid, Paper, Stack, ThemeProvider, Typography } from '@mui/material'
import React from 'react'

const img = '/images/Boletos_Boda.png';

const theme = createTheme({
    palette: {
        primary: {
          main: '#EBA494',
        },
        secondary: {
          main: '#83A788',
          contrastText: 'rgba(255,255,255,0.87)',
        },
        divider: 'rgba(129,129,129,0.4)',
      },
    typography: {
      h1: {
        fontFamily: 'Tangerine',
        fontWeight: 200,
      },
      h2: {
        fontFamily: 'Tangerine',
        fontWeight: 200,
      },
      h3: {
        fontFamily: 'Tangerine',
        fontWeight: 700,
      },
      h4: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      h5: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      h6: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      subtitle2: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      body1: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      body2: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      fontFamily: 'Poppins',
    },
  })

export const Invitation = (props:any) => {
  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background:'white',paddingY:'100px'}}>
        <Grid container direction='row' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
                <ThemeProvider theme={theme}>
                  <div style={{position:'relative',textAlign:'center'}}>
                    <img src={img} style={{width:'80vw'}} />
                    <Typography variant='h4' sx={{ position: 'absolute',top: '17%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'One,Arial',fontWeight:'300',whiteSpace:'nowrap'}}>Tenemos el gusto de invitarte a nuestra boda</Typography>
                    <Typography variant='h6' sx={{ position: 'absolute',top: '24%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'One,Arial',fontWeight:'600',whiteSpace:'nowrap'}}>Datos de los invitados</Typography>
                    {parseInt(props.nPeople) < 2 ?
                        <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{ position: 'absolute',top: '36%',left: '50%',transform:'translate(-50%, -50%)'}}>
                          <Grid item sx={{height:'100px', display:'flex', alignItems: props.sName === null ? 'center' : 'start'}}>
                              <Stack direction="column" spacing={1}>
                                {props.fName.map((name:any) => {
                                  if(name !== ''){
                                    return(
                                      <Typography variant='h2' color='primary' sx={{height:'20px',fontFamily: 'Great Vibes, cursive'}} key={name}>{name}</Typography>
                                    )
                                  }
                                })}
                              </Stack>
                          </Grid>
                        </Grid>
                        : parseInt(props.nPeople) > 2 ?
                        <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{ position: 'absolute',top: '41%',left: '50%',transform:'translate(-50%, -50%)'}}>
                            <Grid item xs={12} >
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  {props.fName.map((name:any) => {
                                    if(name !== ''){
                                      return(
                                        <Typography variant='h4' color='primary' sx={{height:'65px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                      )
                                    }
                                  })}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  {props.sName.map((name:any) => {
                                    if(name !== ''){
                                      return(
                                        <Typography variant='h4' color='primary' sx={{height:'45px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                      )
                                    }
                                  })}
                                </Stack>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{ position: 'absolute',top: '41%',left: '50%',transform:'translate(-50%, -50%)'}}>
                            <Grid item xs={12} >
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  {props.fName.map((name:any) => {
                                    if(name !== ''){
                                      return(
                                        <Typography variant='h4' color='primary' sx={{height:'65px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                      )
                                    }
                                  })}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  {props.sName.map((name:any) => {
                                    if(name !== ''){
                                      return(
                                        <Typography variant='h4' color='primary' sx={{height:'45px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                      )
                                    }
                                  })}
                                </Stack>
                            </Grid>
                        </Grid>
                        }
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '40%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'300',whiteSpace:'nowrap'}}>Sabado 22 de Abril 2023</Typography>
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '26%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'600',whiteSpace:'nowrap'}}>Jardín Vandú</Typography>
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '22%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'300',whiteSpace:'nowrap'}}>Rosales s/n, Atlacomulco, 62560 Jiutepec, Mor.</Typography>
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '8%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'600',whiteSpace:'nowrap'}}>Valido por {props.nPeople} {props.nPeople <= 1 ? 'boleto' : 'boletos'}</Typography>
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '4%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'300',whiteSpace:'nowrap'}}>Vestimenta Formal</Typography>
                    <Typography variant='h5' sx={{ position: 'absolute',bottom: '0%',left: '50%',transform:'translate(-50%, -50%)',fontFamily:'Playfair Display, serif',fontWeight:'500',whiteSpace:'nowrap'}}>Respetuosamente {'<<No niños>>'}</Typography>
                  </div>
                </ThemeProvider>
            </Grid>
        </Grid>
    </Box>
  )
}
