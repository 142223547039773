import { createTheme, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../App.css';
import { useSearchParams } from 'react-router-dom';
import { EventDetails } from '../components/EventDetails';
import { Hero } from '../components/Hero';
import { Timer } from '../components/Timer';
import { Invitation } from './Invitation';
import { InvitationSm } from './InvitationSm';
import { EventGift } from './EventGift';
import { HeroSimple } from './HeroSimple';
import { EventHosp } from './EventHosp';
//fonts
import AmsterdamOne from '../fonts/AmsterdamOne-eZ12l.ttf'
import { DividerLine } from './DividerLine';
import { DividerSaw } from './DividerSaw';
import { AsistForm } from './AsistForm';
import { EventDressCode } from './EventDressCode';

const img = '/images/MyDMain.jpg';
const img2 = '/images/MyD1.jpg';
const img3 = '/images/MyD2.jpg';
const img4 = '/images/MyD3.png';
const img5 = '/images/MyD4.jpg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EBA494',
    },
    secondary: {
      main: '#83A788',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    divider: 'rgba(129,129,129,0.4)',
  },
  typography: {
    h1: {
      fontFamily: 'One, Arial',
      fontWeight: 200,
    },
    h2: {
      fontFamily: 'One',
      fontWeight: 200,
    },
    h3: {
      fontFamily: 'Nanum Myeongjo',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    body2: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    fontFamily: 'Poppins',
  },
})

export const Main = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const fName = searchParams.get("fName");
    const sName = searchParams.get("sName");
    const nPeople = searchParams.get("nPeople");
    const id = searchParams.get("id");


    const namesFirst = fName?.split(',');
    const namesSec = sName?.split(',');

    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    const [WindowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

      
  return (
    <ThemeProvider theme={theme}>
      <Hero image={WindowSize.innerWidth >= 650 ? img : img4}/>
      <Timer />
      {fName === null ? '' : <>{WindowSize.innerWidth <= 650 ? <><DividerSaw color='#ffd6bac4'/> <InvitationSm fName={namesFirst} sName={namesSec} nPeople={nPeople} /></> : <><DividerSaw color='#ffd6bac4'/><Invitation fName={namesFirst} sName={namesSec} nPeople={nPeople} /></> }</>}
      {fName === null ?  '' : <DividerSaw color="white" />}
      <EventDetails />
      <DividerLine position='down' startColor='#f7eee8' endColor='white'/>
      <HeroSimple image1={img2} image2={img3} />
      <DividerLine position='down' startColor='white' endColor='#f7eee8'/>
      <EventHosp />
      <DividerSaw color="#f7eee8"/>
      <EventDressCode />
      <DividerSaw color="white"/>
      <EventGift />
      <AsistForm image={img5} fName={namesFirst} nPeople={nPeople} id={id}/>
    </ThemeProvider>
  )
  

  
}
