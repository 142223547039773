import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
//icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const Hero = (props:any) => {

    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
      const [WindowSize, setWindowSize] = useState(getWindowSize())
    
      useEffect(() => {
          function handleWindowResize() {
            setWindowSize(getWindowSize());
          }
    
          window.addEventListener('resize', handleWindowResize);
    
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
      }, []);

      let backgAt = "fixed";
      WindowSize.innerWidth <= 650 ? backgAt = "scroll" : backgAt = "fixed"
  return (
    <Box sx={{
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: backgAt,
        backgroundImage: 'linear-gradient(rgb(0 0 0 / 40%), rgb(235 164 148 / 40%)), url('+props.image+')'
    }}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            paddingTop='80px'
        >
            <Grid container direction='column' alignItems="center" justifyContent="center">
                <Grid item>
                    {WindowSize.innerWidth >= 650 ? 
                        <Typography color='white' variant='h3' fontSize='4rem' letterSpacing={5} sx={{fontSize:'3.2rem',padding:'20px'}}>22.04.2023</Typography>
                    :
                        <Typography color='white' variant='h3' fontSize='4rem' letterSpacing={5} sx={{fontSize:'2.2rem',padding:'20px',marginBottom:'15px'}}>22.04.2023</Typography>
                    }
                </Grid>
                <Grid item>
                    <div style={{height:'2px',width:'80vw',background:'white',marginBottom:'20px'}}></div>
                </Grid>
                <Grid item sx={{marginTop:'30px'}}>
                    <Grid container direction='row' alignItems="center" justifyContent="center">
                        <Grid item sx={{padding:'16px'}}>
                            <Grid container direction='column' >
                                <Grid item>
                                    {WindowSize.innerWidth >= 650 ? 
                                        <Typography color='white' variant='h1' sx={{fontSize:'5.5rem'}}>Mariana</Typography>
                                    :
                                        <Typography color='white' variant='h1' sx={{fontSize:'4.5rem'}}>Mariana</Typography>
                                    }
                                </Grid>
                                <Grid item sx={{textAlign:'right',marginTop:'25px'}}>
                                    {WindowSize.innerWidth >= 650 ? 
                                        <Typography color='white' variant='h2' sx={{fontSize:'3rem'}}>González</Typography>
                                    :
                                        <Typography color='white' variant='h2' sx={{fontSize:'2rem'}}>González</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{padding:'20px'}}>
                            {WindowSize.innerWidth >= 650 ? 
                                <Typography color='white' variant='h1' fontSize='8rem'>&</Typography>
                            :
                                <Typography color='white' variant='h1' fontSize='5rem' sx={{marginBottom:'20px'}}>&</Typography>
                            }
                        </Grid>
                        <Grid item sx={{padding:'16px'}}>
                            <Grid container direction='column'>
                                <Grid item>
                                    {WindowSize.innerWidth >= 650 ? 
                                        <Typography color='white' variant='h1' sx={{fontSize:'5.5rem'}}>Daniel</Typography>
                                    :
                                        <Typography color='white' variant='h1' sx={{fontSize:'4.5rem'}}>Daniel</Typography>
                                    }
                                </Grid>
                                <Grid item sx={{textAlign:'right',marginTop:'25px'}}>
                                    {WindowSize.innerWidth >= 650 ? 
                                        <Typography color='white' variant='h2' sx={{fontSize:'3rem'}}>Enríquez</Typography>
                                    :
                                        <Typography color='white' variant='h2' sx={{fontSize:'2rem'}}>Enríquez</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <div style={{height:'2px',width:'80vw',background:'white',marginTop:'20px'}}></div>
                </Grid>
                {WindowSize.innerWidth >= 650 ? 
                    <>
                    <Grid item sx={{marginTop:'40px',padding:'5px'}}>
                        <Divider><Typography color='white' variant='h4' fontSize='1.5rem'>Celebremos el amor</Typography></Divider>
                    </Grid>
                    <Grid item sx={{marginTop:'10px',padding:'5px'}}>
                        <LocationOnIcon sx={{marginTop:'20px',color:'white'}}/>
                    </Grid>
                    <Grid item sx={{marginTop:'10px',padding:'5px'}}>
                        <Typography color='white' variant='h4' fontSize='1.5rem'>Jiutepec Morelos</Typography>
                    </Grid>
                    </>
                :
                    <>
                    <Grid item sx={{marginTop:'55px',padding:'5px'}}>
                        <Divider><Typography color='white' variant='h4' fontSize='1.5rem'>Celebremos el amor</Typography></Divider>
                    </Grid>
                    <Grid item sx={{padding:'5px'}}>
                        <LocationOnIcon sx={{marginTop:'20px',color:'white'}}/>
                    </Grid>
                    <Grid item sx={{padding:'5px',paddingBottom:'30px'}}>
                        <Typography color='white' variant='h4' fontSize='1.5rem'>Jiutepec Morelos</Typography>
                    </Grid>
                    </>
                }
            </Grid>
        </Box>

    </Box>
  )
}
