import { Box, Grid, Stack } from '@mui/material'
import React from 'react'

export const HeroSimple = (props:any) => {
  return (
    <Box sx={{
        paddingTop:'30px'
    }}>
      <Grid container direction='row' spacing={5} alignItems="center" justifyContent="center"> 
        <Grid item sm={6} lg={4}>
          <img width="100%" src={props.image1}/>
        </Grid>
        <Grid item sm={6} lg={4} >
          <img width="100%" src={props.image2}/>
        </Grid>
      </Grid>
    </Box>
  )
}
