import { Box, createTheme, Grid, Paper, Stack, ThemeProvider, Typography } from '@mui/material'
import React from 'react'

const img = '/images/Invitacion-sm.png';

const theme = createTheme({
    palette: {
        primary: {
          main: '#EBA494',
        },
        secondary: {
          main: '#83A788',
          contrastText: 'rgba(255,255,255,0.87)',
        },
        divider: 'rgba(129,129,129,0.4)',
      },
    typography: {
      h1: {
        fontFamily: 'Tangerine',
        fontWeight: 200,
      },
      h2: {
        fontFamily: 'Tangerine',
        fontWeight: 200,
      },
      h3: {
        fontFamily: 'Tangerine',
        fontWeight: 700,
      },
      h4: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      h5: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      h6: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      subtitle2: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      body1: {
        fontFamily: 'Tangerine',
        fontWeight: 600,
      },
      body2: {
        fontFamily: 'Poppins',
        fontWeight: 600,
      },
      fontFamily: 'Poppins',
    },
  })

export const InvitationSm = (props:any) => {
  
   console.log(props.sName);
   
   
    return (
        <Box 
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{background:'white',paddingY:'100px'}}>
            <Grid container direction='row' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
            <ThemeProvider theme={theme}>
                    <Paper sx={{backgroundImage:'url('+img+')', backgroundSize:'cover',width:'400px',height:'600px'}}>
                        <Stack spacing={2} alignItems="center" justifyContent="center" >
                            <Typography variant='h5' marginTop={parseInt(props.nPeople) >= 2 ? '150px' : '160px'} sx={{fontFamily:'One,Arial',fontWeight:'300',whiteSpace:'nowrap',fontSize:'1.2rem'}}>Tenemos el gusto de invitarte a nuestra boda</Typography>
                            <Typography variant='h6' marginTop="5px!important" sx={{fontFamily:'One,Arial',fontWeight:'300',whiteSpace:'nowrap',fontSize:'0.8rem'}}>Datos de los invitados</Typography>
                            {parseInt(props.nPeople) < 2 ?
                            <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{marginTop:'5px!important'}}>
                                <Grid item sx={{height:'100px', display:'flex', alignItems:'center'}}>
                                    <Stack direction="column" spacing={1}>
                                      {props.fName.map((name:any) => {
                                          if(name !== ''){
                                            return(
                                              <Typography variant='h4' color='primary' sx={{height:'20px',fontFamily: 'Great Vibes, cursive'}} key={name}>{name}</Typography>
                                            )
                                          }
                                      })}
                                    </Stack>
                                </Grid>
                            </Grid>
                            : parseInt(props.nPeople) > 2 ?
                            <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{marginTop:'0px!important',textAlign:'center'}}>
                                <Grid item sx={{height:'100px', display:'flex', alignItems:'start',justifyContent:"center"}}>
                                    <Stack direction="column" spacing={1}>
                                      {props.fName.map((name:any) => {
                                        if(name !== ''){
                                          return(
                                            <Typography variant='h5' color='primary' sx={{height:'25px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                          )
                                        }
                                      })}
                                    </Stack>
                                </Grid>
                                <Grid item sx={{height:'100px', display:'flex', alignItems:'start',justifyContent:"center"}}>
                                  <Stack direction="column" spacing={1}>
                                      {props.sName.map((name:any) => {
                                        if(name !== ''){
                                          return(
                                            <Typography variant='h5' color='primary' sx={{height:'25px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                          )
                                        }
                                      })}
                                    </Stack>
                                </Grid>
                            </Grid>
                            :
                            <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{marginTop:'0px!important',textAlign:'center'}}>
                                <Grid item sx={{height:'100px', display:'flex', alignItems:'start',justifyContent:"center"}}>
                                    <Stack direction="column" spacing={1}>
                                      {props.fName.map((name:any) => {
                                        if(name !== ''){
                                          return(
                                            <Typography variant='h5' color='primary' sx={{height:'25px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                          )
                                        }
                                      })}
                                    </Stack>
                                </Grid>
                                <Grid item sx={{height:'100px', display:'flex', alignItems:'start',justifyContent:"center"}}>
                                  <Stack direction="column" spacing={1}>
                                      {props.sName.map((name:any) => {
                                        if(name !== ''){
                                          return(
                                            <Typography variant='h5' color='primary' sx={{height:'25px',fontFamily: 'Great Vibes, cursive'}} key={name}>{'  |  '+name+'  |  '}</Typography>
                                          )
                                        }
                                      })}
                                    </Stack>
                                </Grid>
                            </Grid>
                            }
                            <Stack spacing={0} alignItems="center" justifyContent="center" sx={{marginTop: parseInt(props.nPeople) >= 2 ? '66px!important' : '50px!important'}}>
                                <Typography variant='h5' sx={{fontFamily:'Playfair Display, serif',fontWeight:'600',whiteSpace:'nowrap',fontSize:'0.9rem'}}>Sabado 22 de Abril 2023</Typography>
                                <Typography variant='h6' sx={{fontFamily:'Playfair Display, serif',fontWeight:'300',whiteSpace:'nowrap',fontSize:'0.7rem'}}>Jardín Vandu</Typography>
                                <Typography variant='h6' sx={{fontFamily:'Playfair Display, serif',fontWeight:'500',whiteSpace:'nowrap',fontSize:'0.7rem'}}>Rosales s/n, Atlacomulco, 62560 Jiutepec, Mor.</Typography>
                            </Stack>
                            <Stack alignItems="center" justifyContent="center" sx={{marginTop:'45px!important'}}>
                                <Typography variant='h6' sx={{fontFamily:'Playfair Display, serif',fontWeight:'600',whiteSpace:'nowrap',fontSize:'0.9rem'}}>Valido por {props.nPeople} {props.nPeople <= 1 ? 'Boleto' : 'Boletos'}</Typography>
                                <Typography variant='h6' sx={{fontFamily:'Playfair Display, serif',fontWeight:'300',whiteSpace:'nowrap',fontSize:'0.7rem'}}>Vestimenta Formal</Typography>
                                <Typography variant='h6' sx={{fontFamily:'Playfair Display, serif',fontWeight:'500',whiteSpace:'nowrap',fontSize:'0.7rem'}}>Respetuosamente {'<<No niños>>'}</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </ThemeProvider>
            </Grid>
        </Grid>
        </Box>
      )
}
