import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { EMAlert } from './EMAlert';

export const AsistForm = (props:any) => {

    const [confNumber, setconfNumber] = useState(props.nPeople ? props.nPeople : 0)
    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
      const [WindowSize, setWindowSize] = useState(getWindowSize())
      const [openAlert, setOpenAlert] = useState({
        open:false,
        title:'',
        severity:'error',
        message:''
      });

      //close Alert Dialog automatically
        const isOpen = openAlert.open === true;
        useEffect(() => {
            if(isOpen) setTimeout(() => setOpenAlert({...openAlert , open:false}), 5000);
        }, [isOpen])

        const handleAlertClose = () => {
            setOpenAlert({...openAlert , open:false});
        }
    
      useEffect(() => {
          function handleWindowResize() {
            setWindowSize(getWindowSize());
          }
    
          window.addEventListener('resize', handleWindowResize);
    
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
      }, []);

      let backgAt = "fixed";
      WindowSize.innerWidth <= 650 ? backgAt = "scroll" : backgAt = "fixed"

      const handleFormSubmit = async (event:any) => {
        event.preventDefault();
        await axios({
            method: 'get',
            url: 'https://script.google.com/macros/s/AKfycbzfrXDaHhbq5-rw6wCo_0VtO5KGi9CqxSlLG_hAHXZ7O74MEZ-14EkKYrej1V3gWgBJsg/exec',
            params: {
                id:props.id,
                nPeople:props.nPeople,
                confirm:event.target.AsistOption.value,
            },
            headers:{'Content-Type': 'text/plain;charset=utf-8; charset=utf-8'},
        }).then(result => {
            console.log(result);
            setOpenAlert({open:true,title:'Exito',severity:'success',message:result.data.msg});
        }).catch(error => {
            console.log(error);
        })
      }

  return (
    <>
    <Box sx={{
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: backgAt,
        backgroundImage: 'linear-gradient(rgb(0 0 0 / 40%), rgb(235 164 148 / 40%)), url('+props.image+')'
    }}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="70vh"
            paddingTop='80px'
        >
        <Box>
        <Paper sx={{
            boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;', 
            borderRadius: '8px',
            opacity: 1,
            transform: 'opacity 370ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 246ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
            transformOrigin: '123px 351px',
            minWidth: '25vw'}}>
            <Box
                component='form'
                noValidate
                autoComplete="off"
                onSubmit={handleFormSubmit}
                sx={{
                    padding: '30px',
                }}
                >
            <Grid container direction='column' spacing={2} >
                <Grid item sx={{textAlign:'center',marginBottom:'10px'}}>
                    <Typography variant='h5' color='primary'>Confirma tu asistencia</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        color='primary'
                        label="Nombre"
                        type='text'
                        name="name"
                        disabled
                        value={props.fName !== undefined ? props.fName[0] : ''}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        color='primary'
                        label="Número de personas"
                        type='number'
                        name='nPeople'
                        onInput = {(e:any) =>{
                            if(e.target.value > props.nPeople){
                                setconfNumber(props.nPeople)
                            }else{
                                setconfNumber(e.target.value)
                            }
                        }}
                        value={confNumber}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        color='primary'
                        label="Asistencia"
                        type='text'
                        name='AsistOption'
                        select
                    >
                        <MenuItem key='Si' value='Asistire'>
                            Asistire
                        </MenuItem>
                        <MenuItem key='No' value='No Asistire'>
                            No Asistire
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' type='submit'><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Confirmar</Typography></Button>
                </Grid>
            </Grid>
            </Box>
        </Paper>
        </Box>
        </Box>
    </Box>
    <EMAlert open={openAlert.open} AlertClose={handleAlertClose} severity={openAlert.severity} title={openAlert.title} message={openAlert.message}/>
    </>
  )
}
