import React from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";


const render = (status: Status) => {
    console.log(status);
    return <h1>{status}</h1>;
  };

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    console.log(ref);
    
    React.useEffect(() => {
    if (ref.current && !map) {
        setMap(new window.google.maps.Map(ref.current, {}));
    }
    }, [ref, map]);

    React.useEffect(() => {
        if (map) {
          map.setOptions(options);
        }
      }, [map, options]);

      return (
        <>
          <div ref={ref} style={style} />
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement<any>(child, { map });
            }
          })}
        </>
      );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker());
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
  
    return null;
  };

export const GoogleMap = (props:any) => {
  return (
    <Wrapper apiKey={process.env.REACT_APP_GoogleMapsApiKey!} render={render}>
        <Map center={{lat: props.lat, lng: props.long }} zoom={16} style={{ flexGrow: "1", height: props.height,width: props.width }}>
            <Marker key="1" position={{lat: props.lat, lng: props.long }} />
        </Map>
    </Wrapper>
  )
}
