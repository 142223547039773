import { Box, Grid, Typography, Stack, styled, Chip, Button, Dialog, DialogContent, Slide, CardMedia } from '@mui/material'
import MuiPaper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react'
import { GoogleMap } from './GoogleMap';
import { TransitionProps } from '@mui/material/transitions';
//icons
import HotelIcon from '@mui/icons-material/Hotel';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import StarsIcon from '@mui/icons-material/Stars';
import LocationOnIcon from '@mui/icons-material/LocationOn';
//colors
import { grey, pink } from '@mui/material/colors';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Paper = styled(MuiPaper)(({ theme }) => ({
    overflow: 'hidden',
    borderRadius: '10px',
    padding: '20px',
    minWidth:'90px'
}));


export const EventHosp = () => {

    const [open, setopen] = useState(false)

    const handleClose = () => {
        setopen(false);
    }

    const handleOpen = () => {
        setopen(true);
    }

    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    const [WindowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <>
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background:'#f7eee8',paddingY:'100px'}}>
        <Grid container direction='column' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
                <img width={250} src='/images/flower.png'></img>
            </Grid>
            <Grid item sx={{paddingY:'40px'}}>
                <Typography variant='h2' color='secondary' sx={{fontFamily: 'Great Vibes, cursive'}}>Hospedaje</Typography>
            </Grid>
            <Grid direction="row" container alignItems="center" justifyContent="center" spacing={4} sx={{width:'95%',marginLeft:'0px'}}>
                <Grid item sm={8} lg={5} xs={11}>
                    {WindowSize.innerWidth >= 650 ?
                    <Paper sx={{backgroundColor:'white'}} elevation={10}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{marginBottom:'20px'}}>
                            <Chip label="Recomendación de los novios" color='primary' size='medium' sx={{color:'white',marginRight:'auto',fontWeight:'bolder',fontSize:'1rem'}}/>
                            <StarsIcon color='primary' fontSize='medium' />
                        </Stack>
                        <Button variant='contained' color='secondary' onClick={handleOpen} sx={{marginBottom:'10px'}}><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Info Descuentos</Typography></Button>
                        <Stack spacing={2} alignItems="center" justifyContent="center">
                            <HotelIcon color='primary' />
                            <Typography variant='body1'>Hosteleria las quintas</Typography>
                            <HorizontalRuleIcon sx={{color:grey[500]}} />
                            <img  width="110%" height="400px" src='https://hosterialasquintas.com.mx/theme/images/gallery/gallery7.jpg'></img>
                            <LocationOnIcon color='primary' />
                            <Typography variant='body1'>Blvd. Gustavo Díaz Ordaz 9, Cantarranas, 62448 Cuernavaca, Mor.</Typography>
                            <GoogleMap lat={18.919260} long={-99.218040} height='300px' width='500px'/>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" textAlign="center">
                                <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Hoster%C3%ADa+Las+Quintas/@18.919265,-99.2202301,17z/data=!3m1!4b1!4m8!3m7!1s0x85cddfa46be2ecef:0x24dbd51d84b7a4d6!5m2!4m1!1i2!8m2!3d18.919265!4d-99.2180414' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                <Button variant='contained' color='secondary' href='https://hosterialasquintas.com.mx/' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Pagina Hotel</Typography></Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    :
                    <Paper sx={{backgroundColor:'white'}} elevation={10}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{marginBottom:'20px'}}>
                            <Chip label="Recomendación de los novios" color='primary' size='medium' sx={{color:'white',marginRight:'auto',fontWeight:'bolder',fontSize:'1rem'}}/>
                        </Stack>
                        <Button variant='contained' color='secondary' onClick={handleOpen} sx={{marginBottom:'10px'}}><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Info Descuentos</Typography></Button>
                        <Stack spacing={2} alignItems="center" justifyContent="center">
                            <HotelIcon color='primary' />
                            <Typography variant='body1'>Hosteleria las quintas</Typography>
                            <HorizontalRuleIcon sx={{color:grey[500]}} />
                            <img  width="120%" height="350px" src='https://hosterialasquintas.com.mx/theme/images/gallery/gallery7.jpg'></img>
                            <LocationOnIcon color='primary' />
                            <Typography variant='body1' textAlign="center">Blvd. Gustavo Díaz Ordaz 9, Cantarranas, 62448 Cuernavaca, Mor.</Typography>
                            <GoogleMap lat={18.919260} long={-99.218040} height='300px' width='500px'/>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" textAlign="center">
                                <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Hoster%C3%ADa+Las+Quintas/@18.919265,-99.2202301,17z/data=!3m1!4b1!4m8!3m7!1s0x85cddfa46be2ecef:0x24dbd51d84b7a4d6!5m2!4m1!1i2!8m2!3d18.919265!4d-99.2180414' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                <Button variant='contained' color='secondary' href='https://hosterialasquintas.com.mx/' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Pagina Hotel</Typography></Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    }
                </Grid>
                <Grid item sm={8} lg={5} xs={11}>
                    {WindowSize.innerWidth >= 650 ?
                    <Paper sx={{backgroundColor:'white'}} elevation={10} >
                        <Stack spacing={2} alignItems="center" justifyContent="center" >
                        <Button variant='contained' color='secondary' sx={{visibility:'hidden'}}>Info Descuentos</Button>
                        <Chip label="Recomendación de los novios" color='secondary' size='medium' sx={{marginLeft:'auto',fontWeight:'bolder',fontSize:'1rem',visibility:'hidden'}} />
                            <HotelIcon color='primary' />
                            <Typography variant='body1' sx={{textAlign: 'center'}}>Camino Real Sumiya Cuernavaca</Typography>
                            <HorizontalRuleIcon sx={{color:grey[500]}} />
                            <img  width="110%" height="400px" src='https://www.caminoreal.com/storage/app/uploads/public/f8e/2e5/35b/thumb__0_0_0_0_crop.jpg'></img>
                            <LocationOnIcon color='primary' />
                            <Typography variant='body1'>Interior Fraccionamiento, Cam. Real a Sumiya S/N, Jose G. Parres, 62564 Jiutepec, Mor.</Typography>
                            <GoogleMap lat={18.891795} long={-99.1917300} height='300px' width='500px'/>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" textAlign="center">
                                <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Camino+Real+Sumiya+Cuernavaca/@18.891754,-99.1915503,21z/data=!4m18!1m9!3m8!1s0x85cddf6051f1f437:0x68563ce504ce6185!2sCamino+Real+Sumiya+Cuernavaca!5m2!4m1!1i2!8m2!3d18.8918118!4d-99.1917305!3m7!1s0x85cddf6051f1f437:0x68563ce504ce6185!5m2!4m1!1i2!8m2!3d18.8918118!4d-99.1917305' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                <Button variant='contained' color='secondary' href='https://www.caminoreal.com/caminoreal/camino-real-sumiya-cuernavaca' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Pagina Hotel</Typography></Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    :
                    <Paper sx={{backgroundColor:'white'}} elevation={10} >
                        <Stack spacing={2} alignItems="center" justifyContent="center" >
                            <HotelIcon color='primary' />
                            <Typography variant='body1' sx={{textAlign: 'center'}}>Camino Real Sumiya Cuernavaca</Typography>
                            <HorizontalRuleIcon sx={{color:grey[500]}} />
                            <img  width="120%" height="350px" src='https://www.caminoreal.com/storage/app/uploads/public/f8e/2e5/35b/thumb__0_0_0_0_crop.jpg'></img>
                            <LocationOnIcon color='primary' />
                            <Typography variant='body1' textAlign="center">Interior Fraccionamiento, Cam. Real a Sumiya S/N, Jose G. Parres, 62564 Jiutepec, Mor.</Typography>
                            <GoogleMap lat={18.891795} long={-99.1917300} height='300px' width='500px'/>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" textAlign="center">
                                <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Camino+Real+Sumiya+Cuernavaca/@18.891754,-99.1915503,21z/data=!4m18!1m9!3m8!1s0x85cddf6051f1f437:0x68563ce504ce6185!2sCamino+Real+Sumiya+Cuernavaca!5m2!4m1!1i2!8m2!3d18.8918118!4d-99.1917305!3m7!1s0x85cddf6051f1f437:0x68563ce504ce6185!5m2!4m1!1i2!8m2!3d18.8918118!4d-99.1917305' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                <Button variant='contained' color='secondary' href='https://www.caminoreal.com/caminoreal/camino-real-sumiya-cuernavaca' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Pagina Hotel</Typography></Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    }
                </Grid>
            </Grid>
            <Grid direction="row" container alignItems="center" justifyContent="center" spacing={4} sx={{width:'95%',marginLeft:'0px',marginY:'60px'}}>
                <Grid item sm={8} lg={8} xs={10}>
                    <Paper sx={{backgroundColor:'white'}}>
                        <Grid container direction='row' textAlign='center' justifyContent='center' spacing={5} sx={{marginTop:'0px!important'}}>
                            <Grid item lg={6} md={6} xs={8} sx={{margin:'auto',paddingTop:'0!important'}}>
                                <Stack spacing={5}>
                                    <img width='150px' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/2560px-Airbnb_Logo_B%C3%A9lo.svg.png' 
                                    style={{marginLeft:'auto',marginRight:'auto'}}/>
                                    <Button variant='contained' color='primary' href='https://www.airbnb.mx/s/Atlacomulco--Jiutepec/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&price_filter_input_type=0&price_filter_num_nights=2&query=Atlacomulco%2C%20Jiutepec&date_picker_type=calendar&checkin=2023-04-21&checkout=2023-04-23&source=structured_search_input_header&search_type=user_map_move&place_id=ChIJ4fehSm_fzYUR2tegCT87zVw&ne_lat=18.90916899455664&ne_lng=-99.18872832079825&sw_lat=18.89321268768024&sw_lng=-99.21906946917471&zoom=16&search_by_map=true' target='_blank'><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Buscar Alojamiento</Typography></Button>
                                </Stack>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} justifyContent='center'>
                                <img  width="100%" src='https://news.airbnb.com/wp-content/uploads/sites/4/2019/06/PJM020719Q202_Luxe_WanakaNZ_Deck_0193-PulledBack_Bracket_R1.jpg'></img>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    </Box>
    <Dialog
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
            <img width='1000px' src="/images/Reservacion Boda Mariana y Daniel.png"></img>
        </DialogContent>
    </Dialog>
    </>
  )
}
