import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material';
//icons
import CloseIcon from '@mui/icons-material/Close';

export const EMAlert = (props:any) => {

  return (
    <Box sx={{ width: '100%',position:'fixed',top:'0',left:'0',zIndex:'10001' }}>
      <Collapse in={props.open}>
        <Alert
            severity={props.severity}
            variant="filled"
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={props.AlertClose}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
          sx={{ mb: 2 }}
        >
            <AlertTitle>{props.title}</AlertTitle>
            {props.message}
        </Alert>
      </Collapse>
    </Box>
  )
}
