import React from 'react'

export const DividerSaw = (props:any) => {
  return (
    <section id="third">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="140" viewBox="0.5 0.2 176 30" preserveAspectRatio="none" style={{position:'absolute'}}>
            <g transform="translate(-0.21755166,-100.15454)">
                <path fill={props.color} d="M 0.2688579,100.29477 H 200.98548 c 0,0 -99.37375,39.84098 -200.7166221,0 z" />
            </g>
      </svg>
    </section>
  )
}
