import React from 'react'

export const DividerLine = (props:any) => {
  return (
    <div style={{
        width: '100%',
        height: '100px',
        position: 'absolute',
        left: '0px',
        background: props.position === 'up' ? 'linear-gradient(to left bottom, '+props.startColor+' 49%, '+props.endColor+' 50%)'
        :
        'linear-gradient(to left bottom, '+props.startColor+' 49%, '+props.endColor+' 50%)'
    }}></div>
  )
}
