import { Box, Button, Card, CardContent, CardMedia, Chip, Divider, Grid, Stack, styled, Typography } from '@mui/material'
import MuiPaper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react'
import { GoogleMap } from './GoogleMap';
//Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const Paper = styled(MuiPaper)(({ theme }) => ({
    overflow: 'hidden',
    borderRadius: '10px',
    padding: '20px',
    minWidth:'90px'
  }));

export const EventDetails = () => {

    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    const [WindowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


  return (
    <>
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background:'#f7eee8',paddingY:'100px'}}>
        <Grid container direction='column' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item sm={8} lg={6} xs={8}>
                <img width={250} src='/images/flower.png'></img>
            </Grid>
            <Grid item sx={{paddingY:'20px'}}>
                <Typography variant='h2' color='secondary' sx={{fontFamily: 'Great Vibes, cursive'}}>Detalles del Evento</Typography>
            </Grid>
            <Grid item>
            <Grid container direction='row' textAlign='center' justifyContent='center' sx={{marginBottom:'15px'}}>
                <Grid item justifyContent='center' lg={10} md={10} xs={11}>
                    {WindowSize.innerWidth >= 650 ?
                    <Card sx={{ display: 'flex' }} elevation={10}>
                        <CardMedia
                            component="img"
                            sx={{ width: '20vw' }}
                            image="https://images.unsplash.com/photo-1469371670807-013ccf25f16a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '5vw' }} justifyContent='center'>
                            <CardContent>
                                <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Ceremonia Religiosa</Typography>
                                <Stack direction='row' spacing={3}>
                                    <WatchLaterIcon color='primary' />
                                    <Typography variant='h6' color='primary'>3 pm</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <LocationOnIcon color='primary' />
                                    <Typography variant='h6' color='primary'>Jardín Vandu</Typography>
                                </Stack>
                            </CardContent>
                        </Box>
                    </Card>
                    :
                    <Card sx={{ display: 'flex'}} elevation={10}>
                        <CardMedia
                            component="img"
                            sx={{ width: '120px' }}
                            image="https://images.unsplash.com/photo-1469371670807-013ccf25f16a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column'}} justifyContent='center'>
                            <CardContent>
                                <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Ceremonia Religiosa</Typography>
                                <Stack direction='row' spacing={3}>
                                    <WatchLaterIcon color='primary' />
                                    <Typography variant='h6' color='primary'>3 pm</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <LocationOnIcon color='primary' />
                                    <Typography variant='h6' color='primary'>Jardín Vandu</Typography>
                                </Stack>
                            </CardContent>
                        </Box>
                    </Card>
                    }
                </Grid>
            </Grid>
            </Grid>
            <Grid item >
            <Grid container direction='row' textAlign='center' justifyContent='center' sx={{marginBottom:'45px'}}>
                <Grid item justifyContent='center' lg={10} md={10} xs={11}>
                    {WindowSize.innerWidth >= 650 ?
                    <Card sx={{ display: 'flex' }} elevation={10}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '5vw' }} justifyContent='center'>
                            <CardContent>
                                <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Coctel / Recepción</Typography>
                                <Stack direction='row' spacing={3}>
                                    <WatchLaterIcon color='primary' />
                                    <Typography variant='h6' color='primary'>4 pm</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <LocationOnIcon color='primary' />
                                    <Typography variant='h6' color='primary'>Jardín Vandu</Typography>
                                </Stack>
                            </CardContent>
                        </Box>
                        <CardMedia
                            component="img"
                            sx={{ width: '20vw' }}
                            image="https://images.unsplash.com/photo-1557395703-b5202a63d0f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1984&q=80"
                        />
                    </Card>
                    :
                    <Card sx={{ display: 'flex'}} elevation={10}>
                        <Box sx={{ display: 'flex', flexDirection: 'column'}} justifyContent='center'>
                            <CardContent>
                                <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Coctel / Recepción</Typography>
                                <Stack direction='row' spacing={3}>
                                    <WatchLaterIcon color='primary' />
                                    <Typography variant='h6' color='primary'>4 pm</Typography>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <LocationOnIcon color='primary' />
                                    <Typography variant='h6' color='primary'>Jardín Vandu</Typography>
                                </Stack>
                            </CardContent>
                        </Box>
                        <CardMedia
                            component="img"
                            sx={{ width: '120px' }}
                            image="https://images.unsplash.com/photo-1557395703-b5202a63d0f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1984&q=80"
                        />
                    </Card>
                    }
                </Grid>
            </Grid>
            </Grid>
            <Grid item >
                <Grid container direction='row' textAlign='center' justifyContent='center' spacing={2}>
                    <Grid item justifyContent='center' lg={12} md={10} xs={11}>
                        {WindowSize.innerWidth >= 650 ?
                        <Card sx={{ display: 'flex' }} elevation={10}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '3vw' }} justifyContent='center'>
                                <CardContent>
                                    <Chip label="Jardín Vandú" color='primary' size='medium' variant='outlined' sx={{fontSize:'1.4rem',fontWeight:'bolder'}}/>
                                    <Stack direction='column' spacing={3} textAlign='center' justifyContent='center'>
                                        <LocationOnIcon color='primary' sx={{marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}/>
                                        <Typography variant='body1' sx={{marginBottom:'8px!important'}}>Rosales s/n, Atlacomulco, 62560 Jiutepec, Mor.</Typography>
                                        <GoogleMap lat={18.893927914600763} long={-99.20548165306776} height='300px' width='30vw'/>
                                        <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Jard%C3%ADn+Vand%C3%BA/@18.8939254,-99.2076704,17z/data=!3m1!4b1!4m5!3m4!1s0x85cddf6eb4b585b1:0x594798d78b451eac!8m2!3d18.8939254!4d-99.2054817' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                    </Stack>
                                </CardContent>
                            </Box>
                            <CardMedia
                                component="img"
                                sx={{ width: '25vw' }}
                                image="https://grupoparaiso.mx/wp-content/uploads/2020/05/Vandu-248.jpg"
                            />
                        </Card> 
                        :
                        <Card elevation={10}>
                            <CardContent>
                                <Chip label="Jardín Vandú" color='primary' size='medium' variant='outlined' sx={{fontSize:'1.4rem',fontWeight:'bolder'}}/>
                                <Stack direction='column' spacing={3} textAlign='center' justifyContent='center'>
                                    <LocationOnIcon color='primary' sx={{marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}/>
                                    <Typography variant='body1' sx={{marginBottom:'8px!important'}}>Rosales s/n, Atlacomulco, 62560 Jiutepec, Mor.</Typography>
                                    <GoogleMap lat={18.893927914600763} long={-99.20548165306776} height='300px' width='80vw'/>
                                    <Button variant='contained' color='primary' href='https://www.google.com.mx/maps/place/Jard%C3%ADn+Vand%C3%BA/@18.8939254,-99.2076704,17z/data=!3m1!4b1!4m5!3m4!1s0x85cddf6eb4b585b1:0x594798d78b451eac!8m2!3d18.8939254!4d-99.2054817' target="_blank"><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Como LLegar</Typography></Button>
                                </Stack>
                            </CardContent>
                            <CardMedia
                                component="img"
                                sx={{ width: '100vw' }}
                                image="https://grupoparaiso.mx/wp-content/uploads/2020/05/Vandu-248.jpg"
                            />
                        </Card>
                        }
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </Box>
    </>
  )
}
