import { Grid, styled, Typography } from '@mui/material'
import MuiPaper from '@mui/material/Paper';
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'

const Paper = styled(MuiPaper)(({ theme }) => ({
    overflow: 'hidden',
    borderRadius: '10px',
    padding: '20px',
    minWidth:'90px'
  }));

export const Timer = () => {
    const wedDate = new Date('2023-04-22T15:00:00').getTime()
    

    const [wedTime, setwedTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    //this will call the timer function every second
    useEffect(() => {
        const interval = setInterval(() => {
            calculateTime()
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    
    const calculateTime = () =>{
        const currentDate = new Date().getTime()
        const timeLeft = wedDate - currentDate

        const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
        const HoursLeft = Math.floor(timeLeft / (1000 * 60 * 60 )) % 24
        const MinutesLeft = Math.floor(timeLeft / (1000 * 60 )) % 60
        const SecondsLeft = Math.floor(timeLeft / (1000 )) % 60

        setwedTime({days:daysLeft,hours:HoursLeft,minutes:MinutesLeft,seconds:SecondsLeft})
    }

    const getWindowSize = () =>{
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
      const [WindowSize, setWindowSize] = useState(getWindowSize())
    
      useEffect(() => {
          function handleWindowResize() {
            setWindowSize(getWindowSize());
          }
    
          window.addEventListener('resize', handleWindowResize);
    
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
      }, []);

  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background: '#ffd6bac4',paddingY: WindowSize.innerWidth >= 650 ? '100px' : '100px'}}>
        <Grid container direction='column' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
                <img width={250} src='/images/flower.png'></img>
            </Grid>
            <Grid item sx={{paddingY:'20px'}}>
                <Typography variant='h3' color='primary'>22 de Abril 2023</Typography>
            </Grid>
            <Grid item sx={{paddingY:'5px'}}>
                <Typography variant='h5' color='primary'>Faltan</Typography>
            </Grid>
            <Grid item>
                {WindowSize.innerWidth >= 650 ?
                    <Grid container direction='row' alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item>
                            <Paper sx={{padding:'20px',textAlign:'center',minWidth:'100px'}}>
                                <Typography variant='h6' color='primary'>Dias</Typography>
                                <Typography variant='h4' color='secondary'>{wedTime.days}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper sx={{padding:'20px',textAlign:'center',minWidth:'100px'}}>
                                <Typography variant='h6' color='primary'>Horas</Typography>
                                <Typography variant='h4' color='secondary'>{wedTime.hours}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper sx={{padding:'20px',textAlign:'center',minWidth:'100px'}}>
                                <Typography variant='h6' color='primary'>Minutos</Typography>
                                <Typography variant='h4' color='secondary'>{wedTime.minutes}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper sx={{padding:'20px',textAlign:'center',minWidth:'90px'}}>
                                <Typography variant='h6' color='primary'>Segundos</Typography>
                                <Typography variant='h4' color='secondary'>{wedTime.seconds}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction='row' alignItems="center" justifyContent="center" spacing={0.5} sx={{paddingTop:'40px'}}>
                        <Grid item xs={3}>
                            <Paper sx={{padding:'10px',textAlign:'center',minWidth:'70px'}}>
                                <Typography variant='h6' color='primary'>Dias</Typography>
                                <Typography variant='h5' color='secondary'>{wedTime.days}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}> 
                            <Paper sx={{padding:'10px',textAlign:'center',minWidth:'70px'}}>
                                <Typography variant='h6' color='primary'>Horas</Typography>
                                <Typography variant='h5' color='secondary'>{wedTime.hours}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper sx={{padding:'10px',textAlign:'center',minWidth:'70px'}}>
                                <Typography variant='h6' color='primary'>Minutos</Typography>
                                <Typography variant='h5' color='secondary'>{wedTime.minutes}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper sx={{padding:'10px',textAlign:'center',minWidth:'70px'}}>
                                <Typography variant='h6' color='primary'>Segun...</Typography>
                                <Typography variant='h5' color='secondary'>{wedTime.seconds}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    </Box>
  )
}
