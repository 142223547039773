import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'

export const EventGift = () => {

  const getWindowSize = () =>{
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  const [WindowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }

      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
  }, []);
  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background:'#f7eee8',paddingY:'100px'}}>
      <Grid container direction='column' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
                <img width={250} src='/images/flower.png'></img>
            </Grid>
            <Grid item sx={{paddingY:'20px'}}>
                <Typography variant='h2' color='secondary' sx={{fontFamily: 'Great Vibes, cursive', textAlign:'center'}}>Sugerencias de Regalo</Typography>
            </Grid>
            <Grid item sx={{paddingY:'21px'}}>
                <Typography textAlign='center' variant='h5' color='inherit'>Tu prescencia es nuestro mejor regalo, pero en caso de que desees hacernos algun obsequio te compartimos nuestras sugerencias: 
                </Typography>
            </Grid>
            <Grid item>
            <Grid container direction='row' alignItems="center" justifyContent="center" spacing={2} sx={{marginY:'80px'}}>
              <Grid item lg={5} md={6} xs={10} sx={{margin:'auto',paddingTop:'0!important',textAlign:'center', marginBottom:'35px'}}>
                {WindowSize.innerWidth >= 650 ?
                <Card sx={{ display: 'flex' }} elevation={10}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '3vw' }} justifyContent='center'>
                        <CardContent>
                            <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Mesa de Regalos</Typography>
                            <Typography textAlign='center' variant='h5' color='secondary' visibility="hidden">150 263 9235</Typography>
                            <img style={{marginLeft:'auto',marginRight:'auto'}} width='250px' src='https://static.wixstatic.com/media/fa4304_5426139e81ca4415a3086e535a80aba7~mv2.png/v1/fill/w_400,h_200,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo%20sin%20fondo.png'></img>
                            <Button variant='contained' color='secondary' href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51087586' target='_blank'><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Ir a Mesa de Regalos</Typography></Button>
                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: '20vw' }}
                        image="https://images.unsplash.com/photo-1521478706270-f2e33c203d95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    />
                </Card>
                :
                <Card elevation={10}>
                    <CardContent>
                        <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300'}}>Mesa de Regalos</Typography>
                        <img style={{marginLeft:'auto',marginRight:'auto'}} width='250px' src='https://static.wixstatic.com/media/fa4304_5426139e81ca4415a3086e535a80aba7~mv2.png/v1/fill/w_400,h_200,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo%20sin%20fondo.png'></img>
                        <Button variant='contained' color='secondary' href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51087586' target='_blank'><Typography variant='body1' sx={{color:'white',textTransform:'none'}}>Ir a Mesa de Regalos</Typography></Button>
                    </CardContent>
                    <CardMedia
                        component="img"
                        sx={{ width: '100vw' }}
                        image="https://images.unsplash.com/photo-1521478706270-f2e33c203d95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    />
                </Card>
                }
              </Grid>
              <Grid item lg={5} md={6} xs={10} sx={{margin:'auto',paddingTop:'0!important',textAlign:'center',marginTop:'0!important'}}>
                {WindowSize.innerWidth >= 650 ?
                <Card sx={{ display: 'flex' }} elevation={10}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '3.1vw' }} justifyContent='center'>
                        <CardContent>
                            <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300', textAlign:'center'}}>! Suma kilometros a nuestro viaje ¡</Typography>
                            <Stack spacing={1} direction='column'>
                              <Typography textAlign='center' variant='h6' color='inherit'>Numero de Cuenta</Typography>
                              <Typography textAlign='center' variant='h5' color='secondary'>048 336 4607</Typography>
                              <Typography textAlign='center' variant='h6' color='inherit'>Clabe</Typography>
                              <Typography textAlign='center' variant='h5' color='secondary'>0121 8000 4833 646079</Typography>
                            </Stack>
                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: '15.15vw' }}
                        image="https://images.unsplash.com/photo-1446822775955-c34f483b410b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    />
                </Card>
                :
                <Card elevation={10}>
                      <CardContent>
                          <Typography variant='h4' color='primary' sx={{marginY:'20px', fontFamily: 'Great Vibes, cursive', fontWeight: '300', textAlign:'center'}}>! Suma kilometros a nuestro viaje ¡</Typography>
                          <Stack spacing={1} direction='column'>
                            <Typography textAlign='center' variant='h6' color='inherit'>Numero de Cuenta</Typography>
                            <Typography textAlign='center' variant='h5' color='secondary'>048 336 4607</Typography>
                            <Typography textAlign='center' variant='h6' color='inherit'>Clabe</Typography>
                            <Typography textAlign='center' variant='h5' color='secondary'>0121 8000 4833 646079</Typography>
                          </Stack>
                      </CardContent>
                    <CardMedia
                        component="img"
                        sx={{ width: '100vw' }}
                        image="https://images.unsplash.com/photo-1446822775955-c34f483b410b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    />
                </Card>
                }
              </Grid>
            </Grid>
            </Grid>
      </Grid>
        
      </Box>
  )
}
