import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const img = '/images/dressCode.png';

export const EventDressCode = () => {
  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{background:'#white',paddingTop:'100px'}}>
        <Grid container direction='column' alignItems="center" justifyContent="center" spacing={4}>
            <Grid item>
                <img width={250} src='/images/flower.png'></img>
            </Grid>
            <Grid item sx={{paddingY:'40px'}}>
                <Typography variant='h2' color='secondary' sx={{fontFamily: 'Great Vibes, cursive',textAlign:'center'}}>Codigo de Vestimenta</Typography>
            </Grid>
            <Grid item sx={{paddingY:'40px'}}>
                <img width="100%" src={img} />
            </Grid>
        </Grid>
    </Box>
  )
}
